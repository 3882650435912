import React from 'react';

const NoteContext = React.createContext({
    notes : [],
    folders : [],
    filter : null,
    selectedNote : null,
    folderClicked : null,
    noteClicked : null,
    deleteNote : null,
    addNote : null,
    addFolder : null,
    goBack : null,
});

export default NoteContext;