import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import NoteContext from './NoteContext';
import './NoteHeader.css'

export default class NoteHeader extends React.Component {

    static contextType = NoteContext;

    static defaultProps = {
        note : {}
    }

    render() {

        let note = this.props.note;
        let noteClicked = this.context.noteClicked;
        let date = new Date(this.props.note.modified);

        return (
            <div className="noteHeader">
                <Link to={`/note/${note.id}`} onClick={() => noteClicked(note)} className="noteTitle">
                    {note.title}
                </Link>
                    <p>Date modified on {date.toLocaleString()}</p>
                <button onClick={() => this.context.deleteNote(note.id)}>Delete Note</button>
            </div>
        );
    }
}

NoteHeader.propTypes = {
    note : PropTypes.shape({
        id : PropTypes.number,
        title : PropTypes.string,
        modified : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        folder_id : PropTypes.number,
        content : PropTypes.string,
    }).isRequired
}
