import React from 'react';
import {NavLink} from 'react-router-dom';
import NoteContext from './NoteContext';
import './MainSidebar.css'

export default class MainSidebar extends React.Component {
    static contextType = NoteContext;
    
    render() {
        let folderList = this.context.folders.map((folder, i) => {return (
            <li key={i} className="folder">
            <NavLink to={`/folder/${folder.id}`} key={folder.id} onClick={() => this.context.folderClicked(folder)}>
                {folder.folder_name}
            </NavLink>
            </li>
        )});
        return(
            <ul className="sidebar">
                    {folderList}
            </ul>
        );
    }

}