import React from 'react';
import NoteContext from './NoteContext';
import ValidationError from './ValidationError';
import './AddNote.css';

export default class AddNote extends React.Component {

    static contextType = NoteContext;

    constructor(props) {
        super(props);
        this.state = {
          name: {
            value: "",
            touched: false
          },
          content: {
            value: "",
            touched: false
          },
          folder: {
              value: 1,
              touched: false,
          }
        };
      }

      updateName(name) {
        this.setState({ name: { value: name, touched: true } });
      }

      updateContent(content) {
        this.setState({ content: { value: content, touched: true } });
      }

      updateFolder(folder) {
        this.setState({ folder: { value: folder, touched: true } });
      }

      validateName() {
        const name = this.state.name.value.trim();
        if (name.length === 0) {
          return "Name is required";
        } 
      }

      validateContent() {
        const content = this.state.content.value.trim();
        if (content.length === 0) {
          return "Content for note is required";
        }
      }

    render() {

        const nameError = this.validateName();
        const contentError = this.validateContent();

        const folderSelect = this.context.folders.map((folder, i) => {
            return(
            <option key={i} value={folder.id} id={folder.id}>{folder.folder_name}</option>
            )
        });

        return(
        <form className="addNote" onSubmit={e => this.context.addNote(e, {
            title : this.state.name.value,
            modified : Date.now(),
            folder_id : this.state.folder.value,
            content : this.state.content.value,
        })}>
            <h2>Add a note</h2>
            <label>Note Name</label>
            <input type="text" onChange={e => this.updateName(e.target.value)} required/>
            {this.state.name.touched && <ValidationError message={nameError} />}

            <label>Note Content</label>
            <input type="text" onChange={e => this.updateContent(e.target.value)} required/>
            {this.state.content.touched && <ValidationError message={contentError} />}

            <label>Folder</label>
            <select onChange={e => this.updateFolder(e.target.value)}>
                {folderSelect}
            </select>

            <input type="submit"/>
            <button onClick={() => this.context.goBack()}>Cancel</button>
    </form>
    );   
 }
    
}