import React from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import MainSidebar from './Components/MainSidebar';
import NoteList from './Components/NoteList';
import NoteSidebar from './Components/NoteSidebar';
import Note from './Components/Note';
import NoteContext from './Components/NoteContext';
import AddNote from './Components/AddNote';
import AddFolder from './Components/AddFolder';
import SelectionBoundary from './Components/SelectionBoundary';

class App extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            folders : [],
            notes : [],
            filter : null,
            selectedNote : null,
          }
    }


    contactApi(action, url, callback = null, body = null) {
        const options =  {
            method: action,
            body : (body) ? JSON.stringify(body) : null,
            headers: {
                "Content-Type": "application/json",
            }
        };

        fetch(url, options)
            .then(res => {
              if (!res.ok) {
                // get the error message from the response,
                return res.json().then(error => {
                  // then throw it
                  throw error
                })
              } else if (res.status !== 204) {
                return res.json()
              }
              
            })
            .then(data => {
              // call the callback when the request is successful
              // this is where the App component can remove it from state
              if (callback) {
                callback(data);
              }
              
            })
            .catch(error => {
              console.error(error)
            })
        
    }



    folderClicked = (folder) => {
        this.setState({filter : folder});
    }

    noteClicked = (note) => {
        this.setState({selectedNote : note});
    }
    
    headerClicked = () => {
        this.setState({filter : null});
    }
    
    setFolders = (folders) => {
        this.setState({folders : folders});
    }

    setNotes = (notes) => {
        this.setState({notes});
    }

    goBack = () => {
        this.props.history.goBack();
    }

    addFolder = (folder) => {
        const newFolders = [
            ...this.state.folders,
            folder
        ]
        this.setState({folders : newFolders});
    }

    addNote = (note) => {
        const newNotes = [
            ...this.state.notes,
            note
        ]
        this.setState({notes : newNotes});
    }

    requestNoteAdd = (e, note) => {
        e.preventDefault();
        this.props.history.push('/'); 
        this.contactApi('POST', "http://localhost:8000/api/notes", this.addNote, note);
    }

    handleFolderAdd = (e, folder) => {
        e.preventDefault();
        this.props.history.push('/'); 
        this.contactApi('POST', "http://localhost:8000/api/folders", this.addFolder, folder);
    }

    handleNoteDelete = (noteId) => {
        this.props.history.push('/'); 
        this.setState({notes : this.state.notes.filter(note => note.id !== noteId)});
        this.contactApi('DELETE', `http://localhost:8000/api/notes/${noteId}`);
    }

    componentDidMount() {
        this.contactApi('GET', "http://localhost:8000/api/folders", this.setFolders);
        this.contactApi('GET', "http://localhost:8000/api/notes", this.setNotes);
    }

  render(){


    const contextValue = {
        notes : this.state.notes,
        folders : this.state.folders,
        filter : this.state.filter,
        selectedNote : this.state.selectedNote,
        folderClicked : this.folderClicked,
        noteClicked : this.noteClicked,
        deleteNote : this.handleNoteDelete,
        addFolder : this.handleFolderAdd,
        addNote : this.requestNoteAdd,
        goBack : this.goBack
    };

  return (
    <NoteContext.Provider value={contextValue}>
    
        <Header headerClicked={this.headerClicked}/>
        <div className="App">
        <nav>
        <Switch>
            <Route 
                path='/note'
                render = {() => <SelectionBoundary><NoteSidebar/></SelectionBoundary>}
            />
            <Route
                path='/'
                component={MainSidebar}
            />


        </Switch>

        <Link to="/add-folder">
                    <button>Add folder</button>
                </Link>

        <Link to="/add-note">
                 <button>Add Note</button>
        </Link>
        </nav>
        <main>
        <Switch>
        <Route 
            path='/note'
            render = {() => <SelectionBoundary><Note/></SelectionBoundary>}  
        />
        <Route 
            path='/add-note'
            component={AddNote}
        />
        <Route
            path='/add-folder'
            component={AddFolder}
        />
        <Route 
            path='/'
            component={NoteList}
        />

        </Switch>
        </main>
    </div>
    </NoteContext.Provider>
  );
  }
}

export default withRouter(App);



