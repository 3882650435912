import React from 'react';
import NoteHeader from './NoteHeader';
import NoteContext from './NoteContext';

export default class Note extends React.Component {
    static contextType = NoteContext;

    render() {
        if (!this.context.selectedNote) {throw new Error("No selected note")}
        return(
        <div className="note">
            <NoteHeader note={this.context.selectedNote}/>
        <p>
            {this.context.selectedNote.content}
        </p>
    </div>
    
    );
    }

}