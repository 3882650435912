import React from 'react';
import NoteContext from './NoteContext';
import ValidationError from './ValidationError';

export default class AddFolder extends React.Component {

    static contextType = NoteContext;

    constructor(props) {
        super(props);


        this.state = {
            name : {
                value: "",
                touched: false
            }
          }
    }


    updateName(name) {
        this.setState({ name: { value: name, touched: true } });
    }

    validateName() {
        const name = this.state.name.value.trim();
        if (name.length === 0) {
          return "Name is required";
        } 
      }

    render() {

        const nameError = this.validateName();

        return (
            <form onSubmit={e => this.context.addFolder(e, {
                folder_name : this.state.name.value,
    })}>

                    <h2>Add a folder</h2>
                    <label>Folder Name</label>
                    <input type="text" onChange={e => this.updateName(e.target.value)} required/>
                    {this.state.name.touched && <ValidationError message={nameError} />}
                    <input type="submit" />
                    <button onClick={() => this.context.goBack()}>Cancel</button>
            </form>
        );
    }
}