import React from 'react';
import NoteContext from './NoteContext';

export default class NoteSidebar extends React.Component {
    
    static contextType = NoteContext;


    getFolderName(folderId) {
        const folders = this.context.folders;

        for (let i = 0; i < folders.length; i++) {
            if (folders[i].id === folderId) {
                return folders[i].name;
            }
        }

        return null;
    }

    render() {
        if (!this.context.selectedNote) {throw new Error ("No note is currently selected")}
        return (
            <div className="sidebar">
                <button onClick={() => this.context.goBack()}>Go Back</button>
                <h2>{this.getFolderName(this.context.selectedNote.folderId)}</h2>
            </div>
        );
    }

}