import React from 'react';
import NoteHeader from './NoteHeader';
import NoteContext from './NoteContext';
import './NoteList.css'

export default class NoteList extends React.Component {

    static contextType = NoteContext;

    render() {
        let notes = this.context.notes;
        let filter = this.context.filter;
        let noteList = notes.map((note, i) => 
            { 
                if (filter == null || note.folder_id === filter.id) {
                    
               return (<li key={i}><NoteHeader note={note} key={note.id}/></li>);
                } else {
                    
                    return null;
                }
            }
    
        );
        return (
            <ul className="noteList">
                {noteList}
            </ul>
        );
    }
}