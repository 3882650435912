import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './Header.css'

export default class Header extends React.Component {

    static defaultProps = {
        headerClicked : null,
    }

    render() {
        return(
            <Link to="/" onClick={this.props.headerClicked} className="header">
                Noteful
            </Link>
        );
    }
}

Header.propTypes = {
    headerClicked: PropTypes.func.isRequired,
};